/**
 Utilities
 */

.flag-thumb {
    width: 18px;
}

// Background colors
.bg-primary {
    background-color: $primary-color !important;
}

.bg-accent {
    background-color: $accent-color !important;
}

.bg-old {
    background-color: lighten($danger, 25%);
}

.bg-new {
    background-color: lighten($success, 40%);
}

.status {
    &.solid {
        text-transform: capitalize;
        padding: 0.3rem 0.75rem 0.4rem;
        line-height: 100%;
        font-size: 0.95rem;
        border-radius: $border-radius;
        color: $white;
        background-color: $accent-color;

        &.inactive,
        &.revoked,
        &.failed,
        &.expired {
            background-color: $danger;
        }

        &.not-shipped,
        &.undelivered,
        &.disabled,
        &.unpaid,
        &.declined,
        &.closed,
        &.disabled,
        &.out-of-stock,
        &.banned,
        &.rejected {
            background-color: $orange;
        }

        &.pending {
            background-color: $blue;
        }

        &.cancelled,
        &.draft {
            background-color: $black;
        }

        &.active,
        &.approved,
        &.completed,
        &.delivered,
        &.shipped,
        &.enabled,
        &.paid,
        &.authorized,
        &.open,
        &.in-stock,
        &.enabled,
        &.published,
        &.verified {
            background-color: $success;
        }

        &.on-delivery,
        &.timed-out,
        &.unverified {
            background-color: $amber;
        }
    }

    &:not(.solid) {
        text-transform: uppercase;

        &.inactive,
        &.revoked,
        &.failed,
        &.expired {
            color: $danger;
        }

        &.not-shipped,
        &.undelivered,
        &.disabled,
        &.unpaid,
        &.declined,
        &.closed,
        &.disabled,
        &.out-of-stock,
        &.banned,
        &.rejected {
            color: $orange;
        }

        &.pending {
            color: $blue;
        }

        &.cancelled,
        &.draft {
            color: $black;
        }

        &.active,
        &.approved,
        &.completed,
        &.delivered,
        &.shipped,
        &.enabled,
        &.paid,
        &.authorized,
        &.open,
        &.in-stock,
        &.enabled,
        &.published,
        &.verified {
            color: $success;
        }

        &.on-delivery,
        &.timed-out,
        &.unverified {
            color: $amber;
        }
    }
}


// Animations
.zmdi.loading::before {
    animation: zmdi-spin 1s infinite steps(8);
    content: '\f3ec';
}

.zmdi-spin {
    animation: zmdi-spin 2s infinite linear;
}

.zmdi-pulse {
    animation: zmdi-spin 1s infinite steps(8);
}

@keyframes zmdi-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
