/**
 Custom Forms
 */
.checkbox,
.radio {
    & > input[type=checkbox],
    & > input[type=radio] {


        &:checked {
            & ~ .checkbox__label {
                &:before {
                    background-color: $accent-color;
                }
            }

            & ~ .checkbox__label,
            & ~ .radio__label {
                &:before {
                    border-color: $accent-color;
                }
            }
        }
    }
}

.codex-editor {
    font-size: 1.25rem;

    &,
    .ce-paragraph {
        line-height: 1.8;
    }

    .ce-header {
        font-weight: 700;
    }
}
