/**
 Sidebar
 */

.navigation {
    .badge {
        //position: absolute;
        background-color: $accent-color;
        color: $white;
        top: 0.9rem;
        right: 0.6rem;
        padding: 0.3rem 0.6rem;
    }

    & > li > a {
        padding-left: 0.5rem + 1.4rem + 0.6rem;
    }

    & > li > a > i {
        position: absolute;
        left: 0.5rem;
        top: 0.85rem;
    }

    .navigation__sub {
        & > a {
            padding-right: 0.5rem + 1rem + 0.6rem;
        }

        & > a::before,
        & > a::after {
            content: '';
            transform: scale(0);
            position: absolute;
            top: 50%;
            margin-top: -11px;
            font-family: Material-Design-Iconic-Font;
            font-size: 17px;
            right: 15px;
            transition: all;
            transition-duration: 250ms;
        }

        & > a::before {
            content: '\f278';
            transform: scale(1);
        }

        & > a::after {
            content: '\f273';
        }

        &.navigation__sub--toggled > a::before {
            transform: scale(0);
        }

        &.navigation__sub--toggled > a::after {
            transform: scale(1);
        }
    }
}