.widget-contacts__map {
    display: block;
    padding: 3px 3px 4px;

    img {
        width: 100%;
        border-radius: $border-radius;
        margin: -20px 0 -1px;
    }
}
