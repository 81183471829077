.widget-profile {
    background-color: $light-blue;

    .avatar-char {
        background-color: rgba($white, 0.25);
        color: $white;
        margin-right: 1.2rem;
    }
}

.widget-profile__img {
    @include size(120px, 120px);
    border-radius: 50%;
    margin-bottom: 1.2rem;
    border: 5px solid rgba($white, 0.1);
}

.widget-profile__list {
    color: $white;

    .media {
        padding: 1rem 2rem;

        &:nth-child(odd) {
            background-color: rgba($white, 0.1);
        }
    }

    .media-body {
        strong {
            display: block;
            font-weight: $font-weight-bold;
        }

        small {
            color: rgba($white, 0.8);
            font-size: 0.92rem;
        }
    }
}
