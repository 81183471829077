.lg-outer {
    .lg-thumb-item {
        border: 0;

        &:not(.active) {
            opacity: 0.25;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .lg-image {
        border-radius: $border-radius;
    }

    .lg-toogle-thumb {
        border-radius: 50%;
        color: $headings-color;
        height: 51px;
        width: 51px;
        line-height: 41px;
        background-color: $white;
        @include transition(all 500ms);

        &:hover {
            color: $headings-color;
        }
    }

    &:not(.lg-thumb-open) .lg-toogle-thumb {
        top: -70px;
    }

    &.lg-thumb-open .lg-toogle-thumb {
        top: -26px;
    }
}

.lg-thumb.group {
    padding: 20px 0;
}

.lg-slide {
    em {
        font-style: normal;

        h3 {
            color: $white;
            margin-bottom: 5px;
        }
    }

    .video-cont {
        box-shadow: 0 2px 5px rgba($black, .16), 0 2px 10px rgba($black, .12);
    }
}

.lightbox {
    & > a {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            @include transition(all 300ms);
        }

        &:before {
            content: '\f1ee';
            font-family: $font-family-icon;
            font-size: 2.3rem;
            color: $white;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 25px;
            height: 25px;
            line-height: 25px;
            z-index: 2;
            transform: scale(2);
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($black, 0.3);
            z-index: 1;
        }

        &:hover {
            &:before,
            &:after {
                opacity: 1;
            }

            &:before {
                transform: scale(1);
            }
        }
    }
}
