.widget-visitors__stats {
    @include clearfix();
    margin: 0 -0.5rem 2rem;

    & > div {
        border: 1px solid $gray-100;
        padding: 1.2rem 1.5rem 1.1rem;
        float: left;
        margin: 0 0.5rem;
        width: calc(50% - 1rem);

        & > strong {
            font-size: 1.3rem;
            font-weight: normal;
            line-height: 100%;
            color: $headings-color;
        }

        & > small {
            display: block;
            color: $text-muted;
            font-size: 1rem;
            line-height: 100%;
            margin-top: 0.45rem;
        }
    }
}

.widget-visitors__map {
    width: 100%;
    height: 250px;
}

.widget-visitors__country {
    height: 1rem;
    width: 1.5rem;
    vertical-align: top;
    position: relative;
    margin-right: 0.25rem;
    left: -0.1rem;
    border-radius: 1px;
}
