// Roboto Light
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Light-webfont.eot');
    src: url('../fonts/roboto/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Light-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Light-webfont.svg#icon') format('svg');
    font-weight: 300;
    font-style: normal;
}


// Roboto Regular
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Regular-webfont.eot');
    src: url('../fonts/roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Regular-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Regular-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Regular-webfont.svg#icon') format('svg');
    font-weight: 400;
    font-style: normal;
}


// Roboto Medium
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Medium-webfont.eot');
    src: url('../fonts/roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Medium-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Medium-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Medium-webfont.svg#icon') format('svg');
    font-weight: 500;
    font-style: normal;
}


// Roboto Bold
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto/Roboto-Bold-webfont.eot');
    src: url('../fonts/roboto/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Bold-webfont.woff') format('woff'),
    url('../fonts/roboto/Roboto-Bold-webfont.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Bold-webfont.svg#icon') format('svg');
    font-weight: 700;
    font-style: normal;
}
