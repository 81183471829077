/**
 Data Tables
 */

.dataTables_wrapper {
    border-top: 1px solid $table-border-color;

    .table > thead > tr > th {
        border: 1px solid $table-border-color;
        cursor: auto;

        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-right: 0;
        }

        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
            cursor: pointer;
        }
    }

    tbody {
        tr:hover,
        tr:active {
            background-color: lighten($gray-100, 2%);
        }
    }
}

.table th.avatar,
.table td.avatar {
    width: 3rem;

    & > a {
        display: block;
    }

    .listview__img {
        margin: 0;
    }
}

.table .td-checkbox {
    width: $checkbox-radio-size;

    .checkbox__label {
        padding-left: 0;
    }
}

@include media-breakpoint-up(lg) {
    .dataTables_wrapper .table > tbody {
        tr .actions:not(.show-always) {
            visibility: hidden;
        }

        tr:hover,
        tr:active {
            .actions {
                visibility: visible;
            }
        }
    }
}

// Responsive Data Table
@include media-breakpoint-down(md) {
    .dataTables_wrapper .table {
        thead {
            display: none;
        }

        tbody {
            .td-checkbox {
                padding-bottom: $label-margin-bottom;
            }

            th[data-col]:before,
            td[data-col]:before {
                content: attr(data-col) ": ";
                font-weight: bold;
                margin-bottom: $label-margin-bottom / 2;
                display: block;
            }

            th,
            td {
                display: block;
                padding: 0 1.5rem;
                padding-bottom: $grid-gutter-width / 2;
            }

            tr > th:first-child,
            tr > td:first-child {
                padding-top: $grid-gutter-width;
            }

            tr > td:last-child {
                padding-bottom: $grid-gutter-width;
            }

            tr > td:first-child,
            tr > th:first-child,
            tr > td:last-child,
            th,
            td {
                &[class*="bg-"] {
                    padding: $grid-gutter-width $grid-gutter-width;
                }
            }
        }

        tbody,
        &.table-bordered tbody {
            th,
            td {
                border-color: transparent;
            }

            tr > th:first-child,
            tr > td:first-child {
                border-top-color: $table-border-color;
            }
        }
    }
}


tbody.sortable > tr {
    & > td:last-child {
        padding-right: 1.5rem + 2rem;
        position: relative;

        &:after {
            content: '\f30c';
            display: block;
            position: absolute;
            right: 1.5rem;
            top: 1rem;
            font-family: $font-family-icon;
            cursor: move;
            color: $body-color;
            text-align: center;
            vertical-align: middle;
            user-select: none;
            background-color: transparent;
            border: $btn-border-width solid transparent;
            @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
            @include transition($btn-transition);
            @include button-variant($light, $light);
            font-size: 1.4rem;
        }
    }

    @include media-breakpoint-up(lg) {
        & > td:last-child:after {
            display: none;
        }

        &:hover > td:last-child:after {
            display: block;
        }
    }
}
