.select2-container--default {
    .select2-selection--single {
        border-radius: 0;
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid $input-border-color;
        height: auto;

        @include select-bg();

        .select2-selection__arrow {
            display: none;
        }

        .select2-selection__placeholder {
            color: $input-placeholder-color;
        }

        .select2-selection__rendered {
            line-height: $input-btn-line-height;
            padding: $input-btn-padding-y 2px;
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border: 0;
        }
    }

    .select2-selection--multiple {
        background-color: transparent;
        border: 0;
        box-shadow: 0 1px 0 0 $input-border-color;
        border-radius: 0;
        padding-bottom: 1px;

        .select2-selection__choice {
            border-radius: $border-radius;
            border: 0;
            background-color: lighten($input-border-color, 3%);
            padding: 0.4rem 0.8rem;
            color: $input-color;
        }

        .select2-selection__choice__remove {
            margin-right: 0.5rem;
        }

        .select2-selection__rendered {
            padding: 0;
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: transparent;
            opacity: 0.5;
        }
    }

    .selection {
        position: relative;
        display: block;

        &:before, &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 0;
            bottom: 0;
            @include transition(all 200ms);
            transition-timing-function: ease;
            background-color: $blue;
        }

        &:before {
            left: 50%;
        }

        &:after {
            right: 50%;
        }
    }
}

.select2-container--open {
    .selection {
        &:before,
        &:after {
            width: 50%;
        }
    }
}

.select2-dropdown {
    background-color: $white;
    border: 0;
    margin-top: 1px;
    border-radius: $border-radius;
    padding: $dropdown-padding-y 0;
    box-shadow: $dropdown-box-shadow;
    z-index: $header-z-index - 1;
    @include animate(fadeIn, 300ms);

    .select2-results__option {
        padding: 0.65rem $dropdown-item-padding-x;
        @include transition(background-color 300ms, color 300ms);
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
    }

    .select2-results__option[aria-selected=true] {
        position: relative;
        padding-right: 1.5rem;
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;

        &:before {
            font-family: $font-family-icon;
            content: '\f26b';
            position: absolute;
            top: 0.45rem;
            right: 1.5rem;
            font-size: 1.3rem;
            color: $dropdown-link-hover-color;
        }
    }

    .select2-search--dropdown {
        margin-top: -0.5rem;
        border-bottom: 1px solid $input-border-color;
        position: relative;
        margin-bottom: 1rem;

        &:before {
            font-family: $font-family-icon;
            content: '\f1c3';
            font-size: 1.5rem;
            color: $body-color;
            position: absolute;
            left: 1.4rem;
            top: 0.65rem;

        }

        .select2-search__field {
            border: 0;
            background-color: $input-bg;
            height: 2.8rem;
            color: $input-color;
            padding-left: 3.5rem;
        }
    }
}
