/**
Select2
 */

.form-group {
    .select2-container {
        width: 100% !important;
    }
}

.select2-container--default {
    .select2-selection--single {
        border: 1px solid $input-border-color;

        .select2-selection__rendered {
            padding: $input-btn-padding-y $input-padding-x;
        }

        &:before {
            bottom: 0;
        }
    }

    &.select2-container--focus,
    &.select2-container--open {
        .select2-selection--single {
            border-color: $input-focus-border-color;
        }
    }

    .select2-selection--multiple {
        box-shadow: 0 0 0 1px $input-border-color;

        .select2-selection__rendered {
            padding: 0 5px;

            .select2-search__field {
                padding: 0 5px;
            }
        }

        .select2-selection__clear {
            margin-top: 10px;
        }
    }

    &.select2-container--focus,
    &.select2-container--open {
        .select2-selection--multiple {
            box-shadow: 0 0 0 1px $input-focus-border-color;
        }
    }

    .selection {
        &:before, &:after {
            background-color: $accent-color;
        }
    }
}

.input-group {
    .select2-container {
        flex: 1 1;
    }

    .select2-container + .input-group-append {
        margin-left: 0;
    }
}

.select2-icon {
    height: $input-line-height * 1em;
    width: $input-line-height * 1em;
    line-height: $input-line-height * 1em;
    font-size: $input-line-height;
    margin-right: $input-padding-x;
    display: inline-block;
    text-align: center;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-sm;
}
