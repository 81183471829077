/**
 Buttons and Button Groups
 */

.button-group .btn {
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
}

@include media-breakpoint-up(md) {
    .inline-btn-group {
        margin-top: $font-size-root * 2 - 2px;
    }

    .card-body,
    .results__header {
        .row + .inline-btn-group {
            margin-top: 0;
        }
    }
}