.noUi-target {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    background: $gray-100;
    margin: 15px 0;
}

.noUi-horizontal {
    height: 2px;
}

.noUi-vertical {
    width: 3px;
}

.noUi-connect {
    background: $teal;
    box-shadow: none;
}

.noUi-horizontal,
.noUi-vertical {
    .noUi-handle {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        cursor: pointer;
        border: 0;
        box-shadow: none;
        background-color: $teal;
        transition: transform 200ms, box-shadow 200ms;

        &.noUi-active {
            transform: scale(1.5);
            box-shadow: 0 0 0 8px rgba($black, 0.04);
        }

        &:before,
        &:after {
            display: none;
            border: 0
        }
    }
}

.noUi-horizontal {
    .noUi-handle {
        right: -6px !important;
        top: -5px;
    }
}

.noUi-vertical {
    .noUi-handle {
        left: -4px;
        top: -6px;
    }
}

// Theming
@mixin noUiSlider-theme($color) {
    .noUi-connect {
        background: $color;
    }

    &.noUi-horizontal,
    &.noUi-vertical {
        .noUi-handle {
            background-color: $color;
        }
    }
}

.input-slider--blue {
    @include noUiSlider-theme($blue);
}

.input-slider--red {
    @include noUiSlider-theme($red);
}

.input-slider--amber {
    @include noUiSlider-theme($orange);
}

.input-slider--green {
    @include noUiSlider-theme($green);
}
