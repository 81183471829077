/**
 Toggle Blocks and Filter Card
 */

.toggle-block {
    .tg-edit,
    .tg-view {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        // opacity fadein can affect z-index and mess up date dropdowns, fixing that
        position: relative;
        z-index: 1;
    }

    .tg-view + .tg-edit {
        display: none;
    }

    &.toggled .tg-edit {
        display: block;
    }

    &.toggled .tg-view {
        display: none;
    }
}

.filter-card.toggled {
    .card-title {
        margin-bottom: 0;
    }
}

@include media-breakpoint-up(md) {
    .filter-card {
        .actions,
        .card-title {
            display: none;
        }

        &.toggle-block.toggled .tg-view {
            display: block;
        }
    }
}