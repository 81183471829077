/**
Media Library
 */

.square {
    position: relative;
    width: 100%;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .square-content {
        position: absolute;
        width: 100%;
        height: 100%;

        &.card-body {
            padding: 1rem;
        }

        .actions {
            top: 0.8rem;
        }
    }

    .media-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 400%;
        color: $primary;
    }
}

.img-header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.media-thumb {
    .view-overlay {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($black, 0.4);
        z-index: 0;
        @include animate();
    }

    a.view-overlay {
        &:hover,
        &:active,
        &:focus {
            background-color: rgba($black, 0.3);
        }
    }

    .meta-action {
        position: relative;
        z-index: 1;
    }
}

.media-thumb.selected {
    .card-body {
        border: 6px solid $accent-color;

        &:before {
            content: '\f26b';
            font-family: $font-family-icon;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            color: $white;
            width: 4rem;
            height: 4rem;
            font-size: 4rem;
            line-height: 1;
            margin-left: -2rem;
            text-align: center;
            margin-top: -2rem;
        }
    }
}

.modal-footer {
    padding: $modal-header-padding-y;
}

.files-card {
    .table th.avatar, .table td.avatar {
        width: unset;
    }
}
