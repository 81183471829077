.toolbar {
    display: flex;
    flex-direction: row;
    height: 5rem;
    align-items: center;
    padding: 0.05rem $card-spacer-x 0;
    position: relative;

    &:not(.toolbar--inner) {
        background-color: $card-bg;
        border-radius: $border-radius;
        margin-bottom: $grid-gutter-width;
        box-shadow: $card-shadow;
    }

    .actions {
        margin: 0.05rem -0.8rem 0 auto;
    }
}

.toolbar--inner {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 1rem;
    border-radius: $border-radius $border-radius 0 0;
}

.toolbar__nav {
    white-space: nowrap;
    overflow-x: auto;

    & > a {
        color: $text-muted;
        display: inline-block;
        @include transition(color 300ms);

        & + a {
            padding-left: 1rem;
        }

        &.active,
        &:hover {
            color: $headings-color;
        }
    }
}

.toolbar__search {
    position: absolute;
    left: 0;
    top: 0;
    @include size(100%, 100%);
    background-color: $white;
    border-radius: $border-radius;
    padding-left: 3rem;
    display: none;

    input[type=text] {
        @include size(100%, 100%);
        border: 0;
        padding: 0 1.6rem;
        font-size: 1.1rem;
        color: $input-color;

        @include placeholder {
            color: $text-muted;
        }
    }
}

.toolbar__search__close {
    @include transition(color 300ms);
    cursor: pointer;
    position: absolute;
    top: 1.9rem;
    left: 1.8rem;
    font-size: 1.5rem;
    color: $text-muted;

    &:hover {
        color: $body-color;
    }
}

.toolbar__label {
    margin: 0;
    font-size: 1.1rem;
}
