/**
 Google Maps
 */

/* Location Selector */
#location-map {
    height: 300px;
}

.map img,
#location-map img {
    max-width: none;
}

.material-controls {
    margin-top: 16px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#location-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    direction: ltr;
    width: 90%;
}

#location-input:focus {
    border-color: $accent-color;
}