.results__header {
    padding: 2rem 2rem 0;
    border-radius: $border-radius $border-radius 0 0;
    margin-bottom: 2rem;
    background-color: $gray-50;
}

.results__search {
    position: relative;

    input[type="text"] {
        width: 100%;
        border: 0;
        border-radius: $border-radius;
        background-color: $white;
        color: $input-color;
        padding: 0 1rem 0 3rem;
        height: 2.9rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 2px rgba($black, 0.08);
        transition: box-shadow 300ms;

        @include placeholder {
            color: $input-placeholder-color;
        }

        &:focus {
            box-shadow: 0 7px 12px rgba($black, 0.125);
        }
    }

    &:before {
        content: '';
        @include font-icon('\f1c3', 1.3rem);
        position: absolute;
        top: 0.55rem;
        left: 1.1rem;
        z-index: 1;
    }
}

.results__nav {
    border: 0;
}
