/**
 Theme
 */

.header,
.login__block__header,
.login__block__btn,
.top-menu {
    background-color: $primary-color;
}

.navigation {
    .navigation__sub--active > a,
    .navigation__active > a,
    .navigation__active:before {
        color: $accent-color;
    }
}