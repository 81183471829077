/**
 Filter
 */

.results__header {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 2rem;

    & > .actions {
        position: absolute;
        z-index: 2;
        right: 15px;
        top: 18px;
    }

    .form-control {
        border: 0;
        border-radius: $border-radius;
        background-color: $white;
        color: $input-color;
        padding: $input-padding-y $input-padding-x;
        height: $input-height;
        box-shadow: 0 2px 2px rgba($black, 0.08);
        transition: box-shadow 300ms;

        @include placeholder {
            color: $input-placeholder-color;
        }

        &:focus {
            box-shadow: 0 7px 12px rgba($black, 0.125);
        }
    }

    .input-group-text {
        border: 0;
        border-radius: $border-radius;
    }

    .select2-container--default {
        .select2-selection--single {
            border: 0;
            box-shadow: 0 2px 2px rgba($black, 0.08);
            transition: box-shadow 300ms;
            border-radius: $border-radius;

            .select2-selection__rendered {
                background-color: $white;
                border-radius: $border-radius;
                height: $input-height;
            }
        }

        &.select2-container--focus,
        &.select2-container--open {
            .select2-selection--single {
                box-shadow: 0 7px 12px rgba($black, 0.125);
            }
        }

        .select2-selection--multiple {
            box-shadow: 0 2px 2px rgba($black, 0.08);
            transition: box-shadow 300ms;
            border-radius: $border-radius;
            min-height: $input-height;
            background-color: $white;

            .select2-selection__rendered {
                border-radius: $border-radius;
            }
        }

        &.select2-container--focus,
        &.select2-container--open {
            .select2-selection--multiple {
                box-shadow: 0 7px 12px rgba($black, 0.125);
            }
        }

        .selection {
            &:before, &:after {
                display: none;
            }
        }
    }
}