/**
RTL for multilingual fields
 */

.lang-rtl {
    .lang {
        direction: rtl;

        &:not(.no-text-align) {
            text-align: right;
        }

        dd {
            margin-left: auto;
            margin-right: 0;
        }

        .list {
            padding-right: 0;

            & > li::before {
                margin-left: 1.1rem;
                margin-right: 0;
            }
        }

        .card > .actions,
        .card-body > .actions {
            right: auto;
            left: 15px;
        }

        .fileinput .close {
            right: auto;
            left: 0;
        }

        .checkbox,
        .radio {
            & > input[type=checkbox],
            & > input[type=radio] {
                left: auto;
                right: 0;
            }

            .checkbox__label::before,
            .checkbox__label::after,
            .radio__label::before,
            .radio__label::after {
                left: auto;
                right: 0;
            }

            .checkbox__label,
            .radio__label {
                text-align: right;
                padding-right: $checkbox-radio-size * 1.5;
                padding-left: 0;
            }
        }
    }
}
