/**
 File Input
 */

.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;

    & > input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 23px;
        height: 100%;
        width: 100%;
        direction: ltr;
        cursor: pointer;
    }
}

.fileinput {
    margin-bottom: 9px;
    display: inline-block;

    .form-control {
        padding-top: 7px;
        padding-bottom: 5px;
        display: inline-block;
        margin-bottom: 0px;
        vertical-align: middle;
        cursor: text;
    }

    .thumbnail {
        overflow: hidden;
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: middle;
        text-align: center;

        & > img {
            max-width: 100%;
        }
    }

    .btn {
        vertical-align: middle;
    }
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
    display: none;
}

.fileinput-inline .fileinput-controls {
    display: inline;
}

.fileinput-filename {
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
}

.form-control .fileinput-filename {
    vertical-align: bottom;
}

.fileinput.input-group {
    display: table;

    & > * {
        position: relative;
        z-index: 2;
    }

    & > .btn-file {
        z-index: 1;
    }
}

// Not 100% correct, but helps in typical use case
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
    border-radius: 0 $border-radius $border-radius 0;

    &.btn-xs,
    &.btn-sm {
        border-radius: 0 $border-radius-sm $border-radius-sm 0;
    }

    &.btn-lg {
        border-radius: 0 $border-radius-lg $border-radius-lg 0;
    }
}

/*.form-group.has-warning .fileinput {
    .fileinput-preview {
        color: $state-warning-text;
    }
    .thumbnail {
        border-color: $state-warning-border;
    }
}
.form-group.has-error .fileinput {
    .fileinput-preview {
        color: $state-danger-text;
    }
    .thumbnail {
        border-color: $state-danger-border;
    }
}
.form-group.has-success .fileinput {
    .fileinput-preview {
        color: $state-success-text;
    }
    .thumbnail {
        border-color: $state-success-border;
    }
}*/


// Input group fixes
.input-group-addon:not(:first-child) {
    border-left: 0;
}


// Material Admin Specific

.fileinput {
    position: relative;
    width: 100%;

    .close {
        position: absolute;
        top: 5px;
        font-size: 12px;
        float: none;
        opacity: 1;
        font-weight: 500;
        border: 1px solid #ccc;
        width: 19px;
        text-align: center;
        height: 19px;
        line-height: 16px;
        border-radius: 50%;
        right: 0;
        visibility: hidden;

        &:hover {
            background: #eee;
        }
    }

    .btn-file {

    }

    .input-group-addon {
        padding: 0 10px;
        vertical-align: middle;
    }

    .fileinput-preview {
        width: 200px;
        max-width: 100%;
        height: 150px;
        position: relative;
        border: 1px solid $input-border-color;
        line-height: 150px;

        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -13px;
        }

        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.fileinput-exists .close {
    visibility: visible;
}

.file-responsive {
    padding: 0;
    width: 100%;

    .fileinput-preview {
        width: 100%;
    }
}
