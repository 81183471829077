.text-count-wrapper {
    position: absolute;
    bottom: -23px;
    height: 20px;
    width: 100%;
    left: 0;
    font-size: $font-size-sm;
}

.error-text-min {
    float: right;
}
