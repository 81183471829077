/**
Mixins
 */

@mixin animate {
    transition: all 0.5s ease;
}

@mixin gradient {
    background-color: $accent;
    background: linear-gradient(150deg, $accent 0%, $accent-medium 100%);
}

@mixin gradient-dark {
    background-color: darken($accent-medium, 10%);
    background: linear-gradient(150deg, darken($accent, 10%) 0%, darken($accent-medium, 10%) 100%);
}