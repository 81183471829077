.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 1000000;
    text-align: center;
    padding: 3rem;
    overflow: auto;

    & > h1 {
        font-size: 2rem;
    }

    p {
        font-size: 1.2rem;
        color: $text-muted;
    }
}

.ie-warning__downloads {
    background-color: $gray-100;
    padding: 30px 0;
    margin: 30px 0;

    & > a {
        padding: 0 10px;
    }
}
