@font-face {
    font-family: 'Thaana';
    src: url('../fonts/Faruma.otf');
}

html[lang="dv"] {
    .lang {
        dt {
            font-weight: bold;
        }
    }
}
