// Roboto Light
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto-thaana/Roboto-Thaana-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

// Roboto Regular
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto-thaana/Roboto-Thaana-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

// Roboto Medium
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto-thaana/Roboto-Thaana-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

// Roboto Bold
@font-face {
    font-family: Roboto;
    src: url('../fonts/roboto-thaana/Roboto-Thaana-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
