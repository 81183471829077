/**
No Items
 */

.no-items {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 300px);
    color: $text-muted;

    .main-icon {
        font-size: $h1-font-size * 3;
    }
}