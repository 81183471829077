/**
 Categories
 */

.categories-list {
    border: 1px solid $input-border-color;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0 $input-padding-x;

    .checkbox + .checkbox {
        margin-top: $input-padding-y;
    }
}