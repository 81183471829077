/**
 Form Controls
 */

.form-group__bar {
    &:before, &:after {
        background-color: $accent-color;
    }
}

.form-text {
    margin-top: $input-padding-y;
}

ul.invalid-feedback {
    position: static;
    padding-left: $input-padding-x;
    margin-bottom: 0;
    display: block;
}

.form-control {
    border: 1px solid $input-border-color;
}

.form-group--float {
    .form-control {
        border: 0;
        border-bottom: 1px solid $input-border-color;

        &:focus {
            & ~ label {
                color: $accent-color;
            }
        }
    }
}

.form-group--centered .form-group--float > label {
    left: 0;
}

.float-group {
    .form-group--float {
        position: relative;
    }

    .invalid-feedback {
        display: block;
    }
}

@include media-breakpoint-up(md) {
    .card-body {
        .row {
            margin-bottom: $grid-gutter-width;
        }

        .row .form-group,
        .row:last-child {
            margin-bottom: 0;
        }
    }
}

.dataTables_wrapper tr {
    .form-group {
        margin-bottom: 0;
    }

    .form-control {
        background-color: $white;
    }
}

a,
span {
    &.form-control {
        padding: $input-padding-y 0;
        border-color: transparent;
    }
}

a.form-control {
    height: auto;
    min-height: $input-height + 1px;
    color: $link-color;

    &:hover {
        color: $link-hover-color;
    }
}

.code-text {
    font-family: $font-family-monospace;
    font-weight: normal;

    dt, th {
        font-weight: bold;
    }
}

// Slug
.slug {
    .url.form-control {
        padding-left: 0;
        padding-right: 0;
        border-color: transparent;
    }

    .input-group-text {
        padding-left: 0 !important;
        border-color: transparent;
    }
}

.input-group-text.input-group-text-link {
    padding: 0 !important;

    a {
        padding: (($input-padding-y * 2) - 0.125rem) 1rem;
    }
}

input:disabled ~ .input-group-append .disable-w-input {
    pointer-events: none;
    color: $gray-400 !important;
}

/*


.hint {
    cursor: help;
    border: 1px solid #aaa;
    color: #aaa;
    @include border-radius(50%);
    padding: 2px 5px;
    width: 21px;
    height: 21px;
    display: inline-block;
}

*/
