.checkbox,
.radio {
    position: relative;
    line-height: 1.5rem;

    & + .checkbox,
    & + .radio {
        margin-top: 0.5rem;
    }

    &:not(&--inline) {
        display: block;
    }

    &--inline {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    & > input[type=checkbox],
    & > input[type=radio] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        pointer-events: none;

        &:checked {
            & ~ .checkbox__label {
                &:before {
                    background-color: $teal;
                }
            }

            & ~ .checkbox__label,
            & ~ .radio__label {
                &:before {
                    border-color: $teal;
                }

                &:after {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        &:disabled {
            & ~ .checkbox__label,
            & ~ .radio__label {
                opacity: 0.5;
                @include user-select(none);
            }
        }
    }
}

.checkbox__label,
.radio__label {
    position: relative;
    min-width: $checkbox-radio-size;
    min-height: $checkbox-radio-size;
    padding-left: $checkbox-radio-size * 1.5;
    text-align: left;
    margin: 0;

    &:before,
    &:after {
        width: $checkbox-radio-size;
        height: $checkbox-radio-size;
        position: absolute;
        left: 0;
        top: 0;
    }

    &:before {
        content: '';
        border: 2px solid $checkbox-radio-border-color;
        background-color: $checkbox-radio-background-color;
        transition: border-color 200ms, background-color 200ms;
    }

    &:after {
        opacity: 0;
        transform: scale(0);
        transition: transform 150ms, opacity 150ms;
    }
}

.checkbox__label {
    &:before {
        border-radius: $border-radius;
    }

    &:after {
        @include font-icon('\f26b', 1.25rem);
        text-align: center;
        color: $white;
        line-height: $checkbox-radio-size;
    }
}

.radio__label {
    &:before {
        border-radius: 50%;
    }

    &:after {
        content: '';
        width: $checkbox-radio-size - 10;
        height: $checkbox-radio-size - 10;
        border-radius: 50%;
        background-color: $checkbox-radio-checked-border-color;
        top: 5px;
        left: 5px;
    }
}


// Checkbox Char
.checkbox--char {
    & > input[type=checkbox] {
        &:checked {
            & ~ .checkbox__char {
                font-size: 0;
                background-color: $gray-500 !important;

                &:after {
                    transform: scale3d(1, 1, 1);
                    opacity: 1;
                }
            }
        }

        &:not(:checked) {
            & ~ .checkbox__char {
                &:hover {
                    font-size: 0;

                    &:before {
                        transform: scale3d(1, 1, 1);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.checkbox__char {
    border-radius: 50%;
    position: relative;
    height: 40px;
    width: 40px;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    cursor: pointer;
    font-style: normal;
    transition: font-size 200ms ease, background-color 300ms;

    &:before,
    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: transform 300ms ease, opacity 300ms ease;
        font-weight: normal;
    }

    &:before {
        @include font-icon('\f279', 1.35rem);
    }

    &:after {
        @include font-icon('\f26b', 1.5rem);
        transform: scale3d(0, 0, 0);
    }
}
