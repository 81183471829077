#jqstooltip {
    text-align: center;
    padding: 5px 10px;
    border: 0;
    height: auto !important;
    width: auto !important;
    background: $white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;

    .jqsfield {
        font-size: 0.95rem;
        font-weight: 500;
        font-family: inherit;
        text-align: center;
        color: $body-color;

        & > span {
            display: none;
        }
    }
}
