// View More Link
.view-more {
    display: block;
    text-transform: uppercase;
    padding: 1.1rem 0;
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-weight: $font-weight-bold;
    @include transition(color 300ms);

    &:not(.view-more--light) {
        color: $text-muted;

        &:hover {
            color: darken($text-muted, 10%);
        }
    }
}

.view-more--light {
    color: $white;

    &:hover {
        color: rgba($white, 0.8);
    }
}

.load-more {
    text-align: center;
    margin-top: 2rem;

    & > a {
        display: inline-block;
        padding: 0.5rem 1rem;
        border: 2px solid rgba($black, 0.065);
        border-radius: $border-radius;
        color: $body-color;
        @include transition(border-color 300ms);

        & > i {
            font-size: 1.2rem;
            vertical-align: middle;
            margin: 0 0.3rem 0 -0.1rem;
            @include transition(transform 300ms);
        }

        &:hover {
            border-color: rgba($black, 0.12);

            & > i {
                transform: rotate(-360deg);
            }
        }
    }
}

.card-body {
    .view-more {
        padding: 1rem 0 0;
    }
}

// Action Icons
.actions {
    &:not(.actions--inverse) {
        .actions__item {
            color: lighten($body-color, 20%);

            &:hover {
                color: $body-color;
            }

            &.actions__item--active {
                color: darken($body-color, 10%);
            }
        }
    }
}

.actions__item {
    display: inline-block;
    vertical-align: top;
    line-height: 31px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 300ms;
    margin: 0 2px;
    @include size(30px, 30px);

    & > i {
        display: inline-block;
        width: 100%;
    }
}

.actions--inverse {
    .actions__item {
        color: rgba($white, 0.7);

        &:hover {
            color: $white;
        }
    }

    .actions__item--active {
        color: $white;
    }
}


// Icon Lists
.icon-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
        position: relative;
        padding: 0.3rem 0;

        address {
            display: inline-block;
            vertical-align: top;
        }

        & > i {
            width: 2.5rem;
            text-align: center;
            font-size: 1.25rem;
            top: 0.12rem;
            position: relative;
            margin-left: -0.5rem;
        }
    }
}


// Avatars
.avatar-img,
.avatar-char {
    border-radius: 50%;
    @include size(3rem, 3rem);
}

.avatar-char {
    line-height: 3rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;
    color: $white;
    font-style: normal;

    & > .zmdi {
        line-height: 3rem;
    }
}


// Backdrops
.ma-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: $sidebar-z-index - 1;
}


// Tags
.tags {
    & > a {
        color: $body-color;
        border: 2px solid $border-color;
        border-radius: $border-radius;
        padding: 0.45rem 0.8rem;
        display: inline-block;
        margin: 0 0.1rem 0.4rem;
        transition: color 300ms, border-color 300ms;

        &:hover {
            color: darken($body-color, 10%);
            border-color: darken($border-color, 10%);
        }
    }
}
