.fc-scroller {
    height: auto !important;
}

.fc {
    th {
        font-weight: $font-weight-bold;
        padding: 12px 12px 10px;
        text-transform: uppercase;
    }

    table {
        background: transparent;

        tr {
            & > td:first-child {
                border-left-width: 0;
            }
        }
    }

    div.fc-row {
        margin-right: 0;
        border: 0;
    }

    button {
        .fc-icon {
            top: -5px;
        }
    }
}

.fc-unthemed {
    td.fc-today {
        background-color: transparent;

        span {
            color: $orange;
        }
    }
}

.fc-event {
    padding: 0;
    font-size: 0.92rem;
    border-radius: $border-radius;
    border: 0;

    .fc-title {
        padding: 4px 8px;
        display: block;
        color: $white;
        @include text-truncate();
        font-weight: $font-weight-bold;
    }

    .fc-time {
        float: left;
        background: rgba($black, 0.2);
        padding: 2px 6px;
        margin: 0 0 0 -1px;
    }
}

.fc-view {
    &,
    & > table {
        border: 0;
        overflow: hidden;
    }

    & > table {
        & > tbody {
            & > tr {
                .ui-widget-content {
                    border-top: 0;
                }
            }
        }
    }
}

.fc-icon {
    font-family: $font-family-icon;
    font-size: 1.5rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    @include transition(background-color 300ms);

    &:hover {
        background-color: $gray-100;
    }
}

.fc-button {
    border: 0;
    background: transparent;
    box-shadow: none;
}


// Main Calendar
.calendar {
    z-index: 0;

    td, th {
        border-color: lighten($table-border-color, 2%);
    }

    .fc-toolbar {
        height: 250px;
        background-color: $white;
        border-radius: $border-radius $border-radius 0 0;
        position: relative;
        margin-bottom: -2px;
        z-index: 2;

        @include media-breakpoint-down(xs) {
            height: 135px;
        }
    }

    .fc-day-number {
        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
            color: lighten($body-color, 10%);
        }

        padding: 6px 10px;
        width: 100%;
        box-sizing: border-box;
    }

    .fc-day-header {
        text-align: left;
    }

    .fc-day-grid-event {
        margin: 1px 9px;
    }
}


// Widget
.widget-calendar {
    td, th {
        border-color: transparent;
        text-align: center;
    }

    .fc-toolbar {
        h2 {
            font-size: 1.2rem;
            padding-top: 0.3rem;
        }
    }

    .fc-day-number {
        text-align: center;
        width: 100%;
        padding: 0;
    }
}

.widget-calendar__header {
    background-color: $purple;
    border-radius: $border-radius $border-radius 0 0;
    padding: $card-spacer-x $card-spacer-y;

    .actions {
        position: absolute;
        top: 2.5rem;
        right: 1.5rem;
    }
}

.widget-calendar__year {
    font-size: 1.2rem;
    color: rgba($white, 0.8);
    margin-bottom: 0.5rem;
    line-height: 100%;

}

.widget-calendar__day {
    font-size: 1.5rem;
    line-height: 100%;
    color: $white;
}

.widget-calendar__body {
    padding: 1rem;
    margin-top: 1rem;
}


// Tag Color
.event-tag {
    margin-bottom: 1.5rem;

    & > span {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 0 3px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        &,
        & > i {
            @include transition(all 200ms);
        }

        & > input[type=radio] {
            margin: 0;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            cursor: pointer;
            opacity: 0;

            &:checked + i {
                opacity: 1;
                transform: scale(1);
            }
        }

        &:hover {
            opacity: 0.8;
        }

        & > i {
            position: absolute;
            left: 0;
            top: 0;
            @include size(100%, 100%);
            padding: 4px 0 0 7px;
            opacity: 0;
            transform: scale(0);

            &:before {
                content: '\f26b';
                font-family: $font-family-icon;
                color: $white;
                font-size: 1.2rem;
                z-index: 1;
            }
        }
    }
}


// Main Calendar Seasonal headers
[data-calendar-month] {
    @include bg-cover-inline();
    @include transition(background-image 300ms);
}

[data-calendar-month="0"] {
    background-image: url('../img/calendar/january.jpg');
}

[data-calendar-month="1"] {
    background-image: url('../img/calendar/february.jpg');
}

[data-calendar-month="2"] {
    background-image: url('../img/calendar/march.jpg');
}

[data-calendar-month="3"] {
    background-image: url('../img/calendar/april.jpg');
}

[data-calendar-month="4"] {
    background-image: url('../img/calendar/may.jpg');
}

[data-calendar-month="5"] {
    background-image: url('../img/calendar/june.jpg');
}

[data-calendar-month="6"] {
    background-image: url('../img/calendar/july.jpg');
}

[data-calendar-month="7"] {
    background-image: url('../img/calendar/august.jpg');
}

[data-calendar-month="8"] {
    background-image: url('../img/calendar/september.jpg');
}

[data-calendar-month="9"] {
    background-image: url('../img/calendar/october.jpg');
}

[data-calendar-month="10"] {
    background-image: url('../img/calendar/november.jpg');
}

[data-calendar-month="11"] {
    background-image: url('../img/calendar/december.jpg');
}
