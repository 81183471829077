.quick-stats__item {
    padding: 1.5rem 1.5rem 1.45rem;
    @include clearfix();
    border-radius: $border-radius;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
    margin-bottom: $grid-gutter-width;
    display: flex;
    align-items: baseline;
}

.quick-stats__chart,
.quick-stats__info {
    display: inline-block;
    vertical-align: middle;
}

.quick-stats__info {
    min-width: 0;

    & > h2,
    & > small {
        line-height: 100%;
        @include text-truncate();
    }

    & > h2 {
        font-weight: normal;
        margin: 0;
        font-size: 1.3rem;
        color: $white;
    }

    & > small {
        font-size: 1rem;
        display: block;
        color: rgba($white, 0.8);
        margin-top: 0.6rem;
    }
}

.quick-stats__chart {
    margin-left: auto;
    padding-left: 1.2rem;

    @include media-breakpoint-between(sm, lg) {
        display: none;
    }
}

.stats {
    padding-top: 1rem;
}

.stats__item {
    @include card();
    margin-bottom: $grid-gutter-width;
    padding: 1rem;
}

.stats__chart {
    border-radius: $border-radius;
    padding-top: 2rem;
    margin-top: -2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
    overflow: hidden;

    .flot-chart {
        margin: 0 -12px -12px;
    }
}

.stats__info {
    padding: 1.8rem 1rem 0.5rem;
    position: relative;
    text-align: center;

    h2 {
        font-size: 1.3rem;
        margin: 0;

    }

    small {
        display: block;
        font-size: 1rem;
        margin-top: 0.4rem;
        color: $text-muted;
    }
}
