/**
 Actions
 */

.actions__item {
    text-align: center;

    & > span {
        font-family: $font-family-base;
        font-size: $font-size-base;
    }

    &.loading:before {
        animation: zmdi-spin 1s infinite steps(8);
        display: inline-block;
        content: '\f3ec';
    }
}

.table-actions {
    .actions__item {
        width: auto;

        &:before {
            vertical-align: middle;
        }
    }
}

.indicating {
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background-color: $blue;
        border-radius: 50%;
        /*animation-name: flash;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;*/
        position: absolute;
        top: -3px;
        right: 0;
    }
}

// Content title actions
.content__title .actions {
    .actions__item {
        background-color: $accent-color;
        border-radius: 50%;
        color: $white;

        &.delete-link {
            background-color: $danger;
        }

        &:before {
            color: $white;
        }

        .indicator {
            position: absolute;
        }
    }
}

@include media-breakpoint-up(sm) {
    .content__title .actions {
        .actions__item {
            width: auto;
            min-width: 30px + 10px;
            height: 30px + 10px;
            border-radius: 20px;
            padding: 5px 5px 5px 10px;
            position: relative;
            transition: all 0.75s ease;

            &:before {
                vertical-align: top;
            }

            & > span {
                max-width: 0;
                white-space: nowrap;
                transition: all 0.75s ease;
                color: $white;
                display: inline-block;
                opacity: 0;
                vertical-align: top;
            }

            &:hover {
                padding-right: 12px;
            }

            &:hover > span {
                max-width: 999px;
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .content__title .actions {
        position: static;
        margin-top: $grid-gutter-width;
    }
}
